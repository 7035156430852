import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { ButtonComponent } from '../../../components/button/button.component';
import { InputComponent } from '../../../components/input/input.component';
import { selectActiveOrganisation } from '../../../store/selectors/user.selector';
import { formatAddress } from '../../../utils/address';
import { formatDate } from '../../../utils/date';
import { OrganisationOverviewComponent } from "./components/organisation-overview/organisation-overview.component";

export const ORGANISATION_DETAILS_PAGE_URL = 'dashboard';

@Component({
  standalone: true,
  templateUrl: './organisation-details.page.html',
  imports: [CommonModule, ButtonComponent, InputComponent, OrganisationOverviewComponent],
})
export class OrganisationDetailsPage implements OnInit {
  public organisation = {
    organisationName: '',
    customerReference: '',
    email: '',
    phoneNumber: '',
    siteAddress: '',
    dateCreated: '',
  };

  public constructor(private readonly store: Store) {}

  public async ngOnInit(): Promise<void> {
    const organisation = await firstValueFrom(
      this.store.pipe(select(selectActiveOrganisation)),
    );

    if (organisation) {
      this.organisation.organisationName = organisation.name;
      this.organisation.customerReference = organisation.id;
      this.organisation.email = organisation.email;
      this.organisation.phoneNumber = organisation.phoneNumber;
      this.organisation.siteAddress = formatAddress(
        organisation.addressLine1,
        organisation.addressLine2,
        organisation.addressLine3,
        organisation.addressCity,
        organisation.addressCounty,
        organisation.addressPostcode,
      );
      this.organisation.dateCreated = formatDate(
        new Date(organisation.createdAt * 1000),
      );
    }
  }
}
