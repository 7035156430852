import { Organisation } from './organisation.interface';
import { UserStatus } from './status.interface';

export type UserRole = 'ADMIN' | 'OWNER' | 'USER';

export enum UserRoleEnum {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  USER = 'USER'
}

export enum UserGroup {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface UserProfile {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  group: UserGroup;
  createdAt: number;
  updatedAt: number;
}

interface UserInvite {
  organisation: Organisation;
  userId: string;
  role: string;
  createdBy: string;
  createdAt: number;
  updatedAt: number;
  inviteCode: string;
}

export interface User {
  id: string;
  email: string;
  emailVerified: true;
  profile: UserProfile;
  invite: UserInvite | null;
  activeOrganisation: {
    organisation: Organisation;
    userId: string;
    role: UserRole;
    status: UserStatus;
    createdBy: string;
    createdBySummary?: UserSummary | null;
    createdAt: number;
    updatedAt: number;
  };
}

export interface UserSearchParams {
  q?: string;
}

export interface UserSummary {
  id: string;
  firstName: string;
  lastName: string;
}
