<div class="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pb-8">
    
    <ng-container *ngIf="(userRole$ | async) !== userRoles.USER">
        <!-- Card 1 -->
        <a [routerLink]="['/user-management']" title="Registered Users">
        <div class="bg-blue-50 rounded-lg shadow-md p-6 text-center flex flex-col h-full">
            <div class="h-12 flex items-center justify-center">
                <h3 class="text-lg font-semibold">Registered Users</h3>
            </div>
            <div class="flex-1 flex items-center justify-center">
                <ng-container *ngIf="(userOverviewIsPending$ | async)">
                    <app-loading [size]="'w-8'"></app-loading>
                </ng-container>
                <p class="text-2xl font-bold mt-2" *ngIf="(userOverviewIsPending$ | async) === false">
                    {{ (userOverview$ | async)?.total || 0 }}
                </p>
            </div>
        </div>
        </a>
        
        <!-- Card 2 -->
        <a [routerLink]="['/user-management']" title="Users" class="inline-block">
        <div class="bg-blue-50 rounded-lg shadow-md p-6 text-center flex flex-col h-full">
            <div class="h-12 flex items-center justify-center">
                <h3 class="text-lg font-semibold">Users</h3>
            </div>

            <div class="flex-1 flex items-center justify-center">
                <ng-container *ngIf="(userOverviewIsPending$ | async)">
                    <app-loading [size]="'w-8'"></app-loading>
                </ng-container>
                <p class="text-2xl font-bold mt-2" *ngIf="(userOverviewIsPending$ | async) === false">
                    {{ (userOverview$ | async)?.totalUsers || 0 }}
                </p>
            </div>
        </div>
        </a>
        
        <!-- Card 3 -->
        <a [routerLink]="['/user-management']" title="Administrators">
        <div class="bg-blue-50 rounded-lg shadow-md p-6 text-center flex flex-col h-full">
            <div class="h-12 flex items-center justify-center">
                <h3 class="text-lg font-semibold">Administrators</h3>
            </div>
            <div class="flex-1 flex items-center justify-center">
                <ng-container *ngIf="(userOverviewIsPending$ | async)">
                    <app-loading [size]="'w-8'"></app-loading>
                </ng-container>
                <p class="text-2xl font-bold mt-2" *ngIf="(userOverviewIsPending$ | async) === false">
                    {{ (userOverview$ | async)?.totalAdministrators || 0 }}
                </p>
            </div>
        </div>
        </a>
        <hr class="col-span-full border-gray-100 my-4">
    </ng-container>
    <!-- Card 4 -->
    <a [routerLink]="['/jobs-and-tests']" title="Total Jobs">
      <div class="bg-blue-50 rounded-lg shadow-md p-6 text-center flex flex-col h-full">
        <div class="h-12 flex items-center justify-center">
            <h3 class="text-lg font-semibold">Total Jobs</h3>
        </div>
        <div class="flex-1 flex items-center justify-center">
            <ng-container *ngIf="(orderOverviewIsPending$ | async)">
                <app-loading [size]="'w-8'"></app-loading>
            </ng-container>
            <p class="text-2xl font-bold mt-2" *ngIf="(orderOverviewIsPending$ | async) === false">
                {{ (orderOverview$ | async)?.totalOrders || 0 }}
            </p>
        </div>
      </div>
    </a>
    
    <!-- Card 5 -->
    <a [routerLink]="['/jobs-and-tests']" title="Total Samples">
      <div class="bg-blue-50 rounded-lg shadow-md p-6 text-center flex flex-col h-full">
        <div class="h-12 flex items-center justify-center">
            <h3 class="text-lg font-semibold">Total Samples</h3>
        </div>
        <div class="flex-1 flex items-center justify-center">
            <ng-container *ngIf="(orderOverviewIsPending$ | async)">
                <app-loading [size]="'w-8'"></app-loading>
            </ng-container>
            <p class="text-2xl font-bold mt-2" *ngIf="(orderOverviewIsPending$ | async) === false">
                {{ (orderOverview$ | async)?.totalSamples || 0 }}
            </p>
        </div>
      </div>
    </a>
    
    <!-- Card 6 -->
    <a [routerLink]="['/jobs-and-tests']" [queryParams]="{statuses: statuses.REPORTED}" title="Completed Jobs">
      <div class="bg-blue-50 rounded-lg shadow-md p-6 text-center flex flex-col h-full">
        <div class="h-12 flex items-center justify-center">
            <h3 class="text-lg font-semibold">Completed Jobs</h3>
        </div>
        <div class="flex-1 flex items-center justify-center">
            <ng-container *ngIf="(orderOverviewIsPending$ | async)">
                <app-loading [size]="'w-8'"></app-loading>
            </ng-container>
            <p class="text-2xl font-bold mt-2" *ngIf="(orderOverviewIsPending$ | async) === false">
                {{ (orderOverview$ | async)?.completedOrders || 0 }}
            </p>
        </div>
      </div>
    </a>
    
    <!-- Card 7 -->
    <a [routerLink]="['/jobs-and-tests']" [queryParams]="{statuses: statuses.DRAFT}" title="Draft Jobs">
      <div class="bg-blue-50 rounded-lg shadow-md p-6 text-center flex flex-col h-full">
        <div class="h-12 flex items-center justify-center">
            <h3 class="text-lg font-semibold">Draft Jobs</h3>
        </div>
        <div class="flex-1 flex items-center justify-center">
            <ng-container *ngIf="(orderOverviewIsPending$ | async)">
                <app-loading [size]="'w-8'"></app-loading>
            </ng-container>
            <p class="text-2xl font-bold mt-2" *ngIf="(orderOverviewIsPending$ | async)  === false">
                {{ (orderOverview$ | async)?.draftOrders || 0 }}
            </p>
        </div>
      </div>
    </a>
    
    <!-- Card 8 -->
    <a [routerLink]="['/jobs-and-tests']" [queryParams]="{statuses: statuses.COMPLETE + ',' + statuses.PENDING + ',' + statuses.NEW + ',' + statuses.ACTIVE + ',' + statuses.APPROVED + ',' + statuses.HOLD}" title="Pending Jobs">
      <div class="bg-blue-50 rounded-lg shadow-md p-6 text-center flex flex-col h-full">
        <div class="h-10 flex items-center justify-center">
            <h3 class="text-lg font-semibold">Pending Jobs</h3>
        </div>
        <div class="flex-1 flex items-center justify-center">
            <ng-container *ngIf="(orderOverviewIsPending$ | async)">
                <app-loading [size]="'w-8'"></app-loading>
            </ng-container>
            <p class="text-2xl font-bold mt-2" *ngIf="(orderOverviewIsPending$ | async) === false">
                {{ (orderOverview$ | async)?.pendingOrders || 0 }}
            </p>
        </div>
      </div>
    </a>
    
    <!-- Card 9 -->
    <a [routerLink]="['/jobs-and-tests']" [queryParams]="{statuses: statuses.INCOMPLETE + ',' + statuses.WITHDRAWN}" title="Incomplete Jobs">
      <div class="bg-blue-50 rounded-lg shadow-md p-6 text-center flex flex-col h-full">
        <div class="h-12 flex items-center justify-center">
            <h3 class="text-lg font-semibold">Incomplete Jobs</h3>
        </div>
        <div class="flex-1 flex items-center justify-center">
            <ng-container *ngIf="(orderOverviewIsPending$ | async)">
                <app-loading [size]="'w-8'"></app-loading>
            </ng-container>
            <p class="text-2xl font-bold mt-2" *ngIf="(orderOverviewIsPending$ | async) === false">
                {{ (orderOverview$ | async)?.incompleteOrders || 0 }}
            </p>
        </div>
      </div>
    </a>
  </div>