<h1 class="text-3xl font-medium" i18n>Organisation Dashboard</h1>

<div class="mt-12 flex flex-wrap">
  <!-- Left - Organisation Details -->
  <div class="order-2 md:order-1 w-full md:w-[40%] md:p-5">
    <div class="max-w-sm">
      <h2 class="mb-5 mt-1 text-2xl text-gray-500 font-medium">
        {{ organisation.organisationName }}
      </h2>
    </div>

    <div class="max-w-sm">
      <p class="text-gray-600 text-sm font-light">Customer reference</p>

      <p class="mb-5 mt-1 text-xl text-black">
        {{ organisation.customerReference }}
      </p>
    </div>

    <div class="relative max-w-sm">
      <p class="text-gray-600 text-sm font-light">Email address</p>

      <p class="mb-5 mt-1 text-xl text-black">
        {{ organisation.email }}
      </p>
    </div>

    <div class="relative max-w-sm">
      <p class="text-gray-600 text-sm font-light">Phone number</p>

      <p class="mb-5 mt-1 text-xl text-black">
        {{ organisation.phoneNumber }}
      </p>
    </div>

    <div class="max-w-sm">
      <p class="text-gray-600 text-sm font-light">Site address</p>
      <div class="mb-5 mt-2 flex">
        <img src="/assets/images/location.svg" class="h-4 w-auto" alt="" />
        <p class="ml-3 text-black">{{ organisation.siteAddress }}</p>
      </div>
    </div>

    <div class="max-w-sm">
      <p class="text-gray-600 text-sm font-light">Date created</p>

      <p class="mt-1 text-xl text-black">{{ organisation.dateCreated }}</p>
    </div>
  </div>

  <!-- Right - Dashboard -->
  <div class="order-1 md:order-2 w-full md:w-[60%] md:p-5">
    <div class="max-w-sm">
      <h2 class="mb-5 mt-1 text-2xl text-gray-500 font-medium">
        Activity Summary
      </h2>
    </div>

    <app-organisation-overview></app-organisation-overview>
  </div>
</div>